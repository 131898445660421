import React, { createContext, useContext, useState, useEffect } from "react";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { useAuth } from "../../contexts/authContext";

const TransactionsContext = createContext();

export function useTransactions() {
    return useContext(TransactionsContext);
}

export function TransactionsProvider({ children }) {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth(); // Access currentUser from authContext

    useEffect(() => {
        const storedTransactions = localStorage.getItem("transactions");
        if (storedTransactions) {
            setTransactions(JSON.parse(storedTransactions));
            setLoading(false);
        } else {
            fetchTransactionsFromFirestore();
        }
    }, [currentUser]); // Refetch transactions when currentUser changes

    async function fetchTransactionsFromFirestore() {
        if (!currentUser?.transactionsCollection) {
            console.warn("User does not have a transactions collection specified.");
            setLoading(false);
            return;
        }

        // Reset transactions before fetching new data
        setTransactions([]);
        localStorage.removeItem("transactions");

        setLoading(true);
        try {
            const firestore = getFirestore();
            const transactionsRef = collection(firestore, currentUser.transactionsCollection);

            // Fetch transactions from the last 30 days
            const lastMonth = new Date();
            lastMonth.setDate(lastMonth.getDate() - 45);
            const q = query(transactionsRef, where("lastUpdatedDateTime", ">", lastMonth.toISOString()));

            const querySnapshot = await getDocs(q);

            const fetchedTransactions = querySnapshot.docs.map((doc) => {
                const data = doc.data();

                // Calculate the Samsara link
                const lastUpdatedTimestamp = new Date(data.lastUpdatedDateTime).getTime();
                const timestamp = lastUpdatedTimestamp + 12 * 60 * 60 * 1000; // Add 12 hours
                const duration = '172799.999'; // 2 days in the specific format
                const samsaraOrganizationId = currentUser.samsaraOrganizationId;
                const samsaraLink = `https://cloud.samsara.com/o/${samsaraOrganizationId}/devices/${data.samsaraVehicleId}/vehicle?end_ms=${timestamp}&graph=vehicle-fuel-level&autoPan=false&duration=${duration}`;

                return {
                    id: doc.id,
                    truckNumber: data.posVehicleNumber, // Explicitly map this field
                    quantity: data.quantityType === 'Gallons' ? (data.quantity * 3.78541).toFixed(2) : data.quantity,
                    cardNumber: data.cardNumber || 'Unknown',
                    lastUpdatedDateTime: data.lastUpdatedDateTime || 'N/A',
                    minFuelPercent: data.minFuelPercent || 'N/A',
                    maxFuelPercent: data.maxFuelPercent || 'N/A',
                    tankSize: data.tankSizeLitres || 'N/A',
                    samsaraLink, // Add the Samsara link
                    ...data,
                };
            });

            setTransactions(fetchedTransactions); // Update state
            localStorage.setItem("transactions", JSON.stringify(fetchedTransactions)); // Update localStorage
        } catch (error) {
            console.error("Error fetching transactions:", error);
        } finally {
            setLoading(false);
        }
    }

    function clearLocalTransactions() {
        localStorage.removeItem("transactions");
        setTransactions([]);
    }

    useEffect(() => {
        const storedTransactions = JSON.parse(localStorage.getItem("transactions"));

        if (storedTransactions) {
            // console.log("All Transactions:", storedTransactions); // Logs all transactions
            // console.log("Truck Numbers:", storedTransactions.map(tx => tx.truckNumber)); // Logs only truck numbers
            // console.log("Samsara Links:", storedTransactions.map(tx => tx.samsaraLink)); // Logs Samsara links
        } else {
            console.log("No transactions found in localStorage.");
        }
    }, [transactions]);

    const value = {
        transactions,
        setTransactions,
        loading,
        fetchTransactionsFromFirestore,
        clearLocalTransactions,
    };

    return (
        <TransactionsContext.Provider value={value}>
            {!loading && children}
        </TransactionsContext.Provider>
    );
}
